import { Paper as MuiPaper } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { LoadingIconButton } from ".";

const Paper = styled(MuiPaper)`
	position: fixed;
	bottom: ${(props) => props.theme.spacing(5)}px;
	right: ${(props) => props.theme.spacing(5)}px;
	z-index: 1;
	border-radius: 100%;

	opacity: 0.5;
	transition: opacity 0.25s;

	&:hover {
		opacity: 1;
	}
`;

function Fab({ title, icon, disabled, loading, onClick }) {
	return (
		<Paper elevation={3}>
			<LoadingIconButton
				title={title} // TODO pour migrer le title dans le loadingIconButton, j'ai du enlever le placement à droite du tooltip
				icon={React.cloneElement(icon, { fontSize: "large" })}
				color="primary"
				disabled={disabled}
				loading={loading}
				onClick={onClick}
			/>
		</Paper>
	);
}

Fab.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.node.isRequired,
	disabled: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
};

function PropsAreEqual(prev, next) {
	return (
		prev.title === next.title &&
		prev.icon === next.icon &&
		prev.disabled === next.disabled &&
		prev.loading === next.loading &&
		prev.onClick.toString() === next.onClick.toString()
	);
}

export default React.memo(Fab, PropsAreEqual);
